import { FC } from 'react';
import moment from 'moment';
import { Col, DatePicker, Form, FormInstance, Input, Row, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import apiRoutes from 'config/apiRoute';
import SelectSearch from '../SelectSearch';
import UserAvatar from '../UserAvatar';
import styles from './Timer.module.less';

const { TextArea } = Input;
const { Text } = Typography;

const usersCanSelectUser = ['christopher@savageglobalent.com', 'santiago@savageglobalent.com', 'marcelo@savageglobalent.com'];

interface TimerFormProps {
  form: FormInstance;
  projectId?: string;
  initialUser?: any;
  showUserSelection?: boolean;
  onChangeTimer?: (value: string) => void;
}

const TimerForm: FC<TimerFormProps> = ({ form, projectId, initialUser, showUserSelection, onChangeTimer }) => {
  const user = useSelector((store: any) => store.auth.user);
  const wrapShowUserSelection = showUserSelection && usersCanSelectUser.includes(user.email);

  return (
    <Form form={form} className="mb-m">
      <Row className="mb-m" gutter={10} wrap={false}>
        <Col style={{ flex: '1 1 120px' }}>
          <Form.Item initialValue="00:00" name="time" noStyle>
            <Input
              onFocus={(event) => {
                setTimeout(() => {
                  event.target.selectionStart = event.target.value.length;
                  event.target.selectionEnd = event.target.value.length;
                }, 0);
              }}
              onChange={(e) => {
                const value = parseFloat(e.target.value.replace(/\D/g, '')) / 100;
                const newValue = value.toFixed(2).replace('.', ':');

                form.setFieldsValue({ time: newValue });

                onChangeTimer?.(newValue);
              }}
              placeholder="00:00"
              suffix={
                <div className={styles.suffix}>
                  <ClockCircleOutlined />
                </div>
              }
            />
          </Form.Item>
        </Col>

        <Col flex={1}>
          <Form.Item name="date" noStyle>
            <DatePicker format="MM/DD/YYYY" defaultValue={moment(Date.now())} style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        {wrapShowUserSelection && (
          <Col xs={11}>
            <SelectSearch
              initialOptions={[initialUser ?? user]}
              initialValue={user.uuid}
              render={(row: any) => {
                return (
                  <Row wrap={false} align="middle" gutter={[6, 0]}>
                    <Col>
                      <UserAvatar size="small" user={row} />
                    </Col>
                    <Col flex="auto">
                      <Text ellipsis>{row?.name}</Text>
                    </Col>
                  </Row>
                );
              }}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              style={{ width: '100%' }}
              searchKey="search_term"
              name="user_id"
              placeholder="Select user"
              api={projectId ? `${apiRoutes.BASE_URL}/projects/${projectId}/users` : apiRoutes.PROJECT_USERS}
              noStyle
            />
          </Col>
        )}
      </Row>

      <Form.Item
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input your Comment!',
          },
        ]}
        noStyle
      >
        <TextArea placeholder="Comment" rows={5} />
      </Form.Item>
    </Form>
  );
};

export default TimerForm;
